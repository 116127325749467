.footer {
  background: #000;
  padding: 20px 0 5px 0;
  font-size: 16px;
  position: absolute;
  color: $white-color;
  width: 100%;
}

.copyright {
  margin-bottom: 20px !important;
  float: left;
}

.footer .copyright p {
  font-size: 14px !important;
}

.footer .copyright a {
  color: $secondary-color;
}

.footer .social-icon {
  padding-left: 0px !important;
}

.footer .social-icon li {
  display: inline-block;
}

.footer .social-icon li a {
  color: #000;
  background: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;
  text-align: center;
  border-radius: 100%;
  font-size: 22px;
  margin: 0px 12px 0px 6px;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.social-icon .linkedin:hover {
  color: #4875B4;
}

.social-icon .facebook:hover {
  color: #3B5998;
}

.social-icon .twitter:hover {
  color: #00aced;
}

.social-icon .instagram:hover {
  color: #fb3958;
}

.social-icon .medium:hover {
  color: #03a87c;
}

.social-icon .tiktok:hover {
  color: #fe2c55;
}

.social-icon .youtube:hover {
  color: #FF0000;
}

.social-icon .telegram:hover {
  color: #0088cc;
}

.upper-section-footer {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 10px;
}

.footer-link {
  display: block;
  cursor: pointer;
  color: #fff;
  text-transform: capitalize;
}

.footer-link:hover {
  color: $secondary-color;
  text-decoration: none;
}