.header-bg {
  height: 100%;
  padding-bottom: 40px;
}

.brand-text {
  display: inline-block;
  color: black !important;
  position: relative;
  font-size: 22px !important;
  bottom: -3px;
  padding-left: 20px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.brand-text-large {
  display: inline-block;
  color: black !important;
  position: relative;
  font-size: 32px !important;
  bottom: -5px;
  padding-left: 20px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.fixed-navbar {
  background-color: white;
  position: fixed !important;
  z-index: 999;
  top: 0;
  width: 100%;
}

.navbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.nav-item {
  padding-right: 15px;
  font-size: 18px;
  color: black;
  font-weight: 700;
  padding: 5px 10px 5px 10px;
  display: inline-block;
}

.nav-item:hover {
  color: #192BC2;
  text-decoration: none;
}

.nav-item-elite {
  padding-right: 15px;
  font-size: 18px;
  color: black;
  font-weight: 700;
  padding: 5px 10px 5px 10px;
  display: inline-block;
}

.nav-item-elite:hover {
  color: #c70404;
  text-decoration: none;
}

.nav-item-left {
  margin-left: 23px;
  font-size: 18px;
  font-weight: 500;
  color: #656973;
  padding: 5px 10px 5px 10px;
  display: inline-block;
}

.nav-item-left:hover {
  color: black;
  text-decoration: none;
}

.nav-item-dropdown {
  padding-right: 15px;
  font-size: 18px;
  color: black;
  padding: 5px 10px 5px 10px;
  display: inline-block;
  text-decoration: none;
}

.nav-item-dropdown:hover {
  color: #192BC2;
}

.nav-item-dropdown-elite {
  padding-right: 15px;
  font-size: 18px;
  color: black;
  padding: 5px 10px 5px 10px;
  display: inline-block;
  text-decoration: none;
}

.nav-item-dropdown-elite:hover {
  color: #c70404;
}

.drawer-link {
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.drawer-link:hover {
  color: $secondary-color;
  text-decoration: none;
  cursor: pointer;
}

.drawer-link-elite {
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.drawer-link-elite:hover {
  color: #d11a1a;
  text-decoration: none;
  cursor: pointer;
}

.nav-link {
  color: #192BC2;
}

.nav-link:hover {
  color: #150578;
  text-decoration: none;
}

.dropdown-item:hover {
  background-color: transparent !important;
}

.dropdown-item {
  padding-left: 20px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.dropdown-menu {
  box-shadow: 0 2px 12px rgba(0,0,0,.03);
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.dropdown-divider {
  width: 100%;
  text-align: center;
  border-bottom: 0.1px solid rgb(228, 228, 228);
  margin: 1px 0 1px !important;
  color: black;
}

.dropdown-menu-right {
  margin-top: 14px !important;
}

.header-pill {
  background-color: #192BC2;
  border-radius: 20px;
  display: inline-block;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.header-pill:hover {
  color: #fff;
  background-color: #150578;
  text-decoration: none;
}

.header-pill-elite {
  background-color: #c70404;
  border-radius: 20px;
  display: inline-block;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.header-pill-elite:hover {
  color: #fff;
  background-color: #c70404;
  text-decoration: none;
}

@media (min-width: 320px) and (max-width: 480px) {
  .homepage-header {
    height: 400px;
  }

  .nav-item {
    font-size: 16px;
  }

  .nav-item-left {
    margin-left: 0px;
    font-size: 16px;
    font-weight: 600;
    color: black;
    padding: 5px 10px 5px 10px;
    display: inline-block;
  }

  .nav-link {
    font-size: 16px;
    padding: 1rem 1rem;
  }

  .navbar-toggler {
    padding-top: 10px !important;
  }

  .navbar-collapse {
    margin-top: 15px;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(21, 5, 120)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  }

  .nav-item-dropdown {
    font-size: 16px;
    padding-bottom: 10px;
  }

  .dropdown-menu {
    padding: 0px !important;
    border: none !important;
    box-shadow: none !important;
  }

  .dropdown-item {
    padding-left: 20px !important;
  }

  .dropdown-menu-right {
    margin-top: 0px !important;
  }

  .btn-navbar {
    margin-left: 15px;
    margin-top: 20px;
  }

  .header-pill {
    margin-left: 8px;
    font-size: 16px;
    margin-top: 10px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .nav-item {
    font-size: 16px;
  }

  .nav-item-left {
    margin-left: 0px;
    font-size: 16px;
    font-weight: 700;
    color: black;
    padding: 5px 10px 5px 10px;
    display: inline-block;
  }

  .nav-link {
    font-size: 16px;
    padding: 1rem 1rem;
  }

  .navbar-toggler {
    padding-top: 10px !important;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(21, 5, 120)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  }

  .navbar-collapse {
    margin-top: 15px;
  }

  .nav-item-dropdown {
    font-size: 16px;
  }

  .dropdown-menu {
    padding: 0px !important;
    border: none !important;
    box-shadow: none !important;
  }

  .dropdown-item {
    padding-left: 20px !important;
  }

  .dropdown-menu-right {
    margin-top: 0px !important;
  }

  .header-pill {
    margin-left: 8px;
    font-size: 16px;
  }
}

@media (min-width: 767px) and (max-width: 1200px) {
  .brand-text {
    display: none;
  }
}