.primary-btn {
  background-color: #192BC2 !important;
  border-color: transparent !important;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.primary-btn:hover {
  background-color: #150578 !important;
}

.white-btn {
  background-color: #fff !important;
  color: #000 !important;
  border-color: transparent !important;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.white-btn:hover {
  background-color: rgb(250, 250, 250) !important;
  color: #000;
}

.secondary-btn {
  background-color: #449dd1 !important;
  border-color: transparent !important;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.secondary-btn:hover {
  background-color: #3692c7 !important;
}

.elite-btn {
  background-color: #c70404 !important;
  border-color: transparent !important;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.elite-btn:hover {
  background-color: #d11a1a !important;
}

.elite-base-btn {
  background-color: #fff !important;
  color: #000 !important;
  border-color: transparent !important;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.elite-base-btn:hover {
  background-color: rgb(250, 250, 250) !important;
  color: #000;
}

.elite-accent-btn {
  background-color: #000 !important;
  color: #fff;
  border-color: transparent !important;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.elite-accent-btn:hover {
  background-color: rgb(27, 27, 27) !important;
  color: #fff;
}

.black-btn {
  background-color: #000 !important;
  border-color: transparent !important;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.black-btn:hover {
  background-color: rgb(32, 32, 32) !important;
}

.yellow-pill {
  background-color: #ffc107;
  border-radius: 20px;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.yellow-btn {
  background-color: #ffc107 !important;
  border-color: transparent !important;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.yellow-btn:hover {
  background-color: #ffae00 !important;
}

.green-pill {
  background-color: #28a745;
  border-radius: 20px;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.green-pill:hover {
  background-color: #1f8d38;
  cursor: pointer;
}

.green-btn {
  background-color: #28a745 !important;
  border-color: transparent !important;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.green-btn:hover {
  background-color: #1f8d38 !important;
}

.blue-pill {
  background-color: #007bff;
  border-radius: 20px;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.blue-btn {
  background-color: #007bff !important;
  border-color: transparent !important;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.blue-btn:hover {
  background-color: #0044ff !important;
}

.red-pill {
  background-color: #dc3545;
  border-radius: 20px;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.red-btn {
  background-color: #dc3545 !important;
  border-color: transparent !important;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.red-btn:hover {
  background-color: #c71e2f !important;
}

.info-pill {
  background-color: #17a2b8;
  border-radius: 20px;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.info-btn {
  background-color: #17a2b8 !important;
  border-color: transparent !important;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.info-btn:hover {
  background-color: #088fa3 !important;
}

.grey-pill {
  background-color: #b7bcca;
  border-radius: 20px;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.grey-btn {
  background-color: #b7bcca !important;
  border-color: transparent !important;
  cursor: pointer;
  color: #000;
  padding: 10px 15px 10px 15px;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.grey-btn:hover {
  background-color: #a0a4af !important;
  color: #000;
}

.cancel-btn {
  background-color: transparent !important;
  border-color: transparent !important;
  cursor: pointer;
  color: #000 !important;
  padding: 10px 15px 10px 15px;
}

.cancel-btn:hover {
  background-color: transparent !important;
  color: #000 !important;
  text-decoration: underline;
}

.search-btn {
  background-color: #192BC2 !important;
  border-color: transparent !important;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.search-btn:hover {
  background-color: #150578 !important;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 24px;
}

.on {
  color: $primary-color;
}

.off {
  color: #ccc;
}